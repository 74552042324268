import { Component, inject } from '@angular/core';
import { ServiceService } from '../../services/service/service.service';
import { CustomerService } from '../../services/customer/customer.service';
import { OrderService } from '../../services/order/order.service';

@Component({
    selector: 'app-company-overview',
    templateUrl: './service-overview.component.html',
    styleUrls: ['./service-overview.component.scss'],
})
export class ServiceOverviewComponent {
    serviceUIDs: string[] = [];

    serviceService: ServiceService = inject(ServiceService);
    customerService: CustomerService = inject(CustomerService);
    orderService: OrderService = inject(OrderService);

    constructor() {
        this.serviceService.fetchAllUIDs().subscribe((serviceUIDs: string[]): void => {
            this.serviceUIDs = serviceUIDs;
        });
        if (this.customerService.getToken()) {
            this.customerService.authenticate(undefined);
        }

        this.orderService.order.service = undefined;
    }
}
