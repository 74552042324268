import { Component } from '@angular/core';
import { CustomerService } from '../../services/customer/customer.service';

@Component({
    selector: 'app-management',
    templateUrl: './management.component.html',
    styleUrls: ['./management.component.scss'],
})
export class ManagementComponent {
    constructor(private customerService: CustomerService) {
        if (this.customerService.getToken()) {
            this.customerService.authenticate(undefined);
        }
    }
}
