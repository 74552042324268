import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrderComponent } from './components/order/order.component';
import { CustomerInfoComponent } from './components/customer-info/customer-info.component';
import { OrderConfirmComponent } from './components/order-confirm/order-confirm.component';
import { ImpressumComponent } from './components/impressum/impressum.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { OrderEventComponent } from './components/order-event/order-event.component';
import { ServiceOverviewComponent } from './components/service-overview/service-overview.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ManagementComponent } from './components/management/management.component';
import { CustomerOrderHistoryComponent } from './components/customer-order-history/customer-order-history.component';
import { ManagementSettingsComponent } from './components/management/management-settings/management-settings.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'bestellen',
        pathMatch: 'full',
    },
    {
        path: 'overview',
        redirectTo: 'bestellen',
        pathMatch: 'full',
    },
    {
        path: 'bestellen',
        component: ServiceOverviewComponent,
        data: { routeName: 'Essen bestellen' },
    },
    {
        path: 'event/:eventId',
        component: OrderEventComponent,
        data: { routeName: 'Event' },
    },
    {
        path: 'checkout/:serviceUID',
        component: CheckoutComponent,
        data: { routeName: 'Checkout' },
    },
    {
        path: 'info/:serviceUID',
        component: CustomerInfoComponent,
        data: { routeName: 'Customer Info' },
    },
    {
        path: 'confirm/success',
        component: OrderConfirmComponent,
        data: { routeName: 'Order Confirm' },
    },
    {
        path: 'impressum',
        component: ImpressumComponent,
        data: { routeName: 'Impressum & Datenschutz' },
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'register',
        component: RegisterComponent,
    },
    {
        path: ':serviceUID',
        component: OrderComponent,
    },
    // force reroute Metzgerei Rettensteiner Scharfetter id to new UID implementation
    // TODO: remove
    {
        path: 'service/order/1',
        redirectTo: 'metzgerei-rettensteiner-scharfetter',
        pathMatch: 'full',
    },
    {
        path: 'management',
        component: ManagementComponent,
        children: [
            {
                path: '',
                redirectTo: 'overview',
                pathMatch: 'full',
            },
            {
                path: 'overview',
                component: CustomerOrderHistoryComponent,
            },
            {
                path: 'settings',
                component: ManagementSettingsComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
