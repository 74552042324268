<ng-container (keydown.enter)="onFormSubmit()">
    <div class="register__view dynamic__padding--horizontal--sm">
        <div *ngIf="this.successfulRequest"
             class="register__success">
            <span class="register__success__title dynamic__text--sm">
                Willkommen bei MyMeal
                <mat-icon class="register__success__title__icon">sentiment_very_satisfied</mat-icon>
            </span>
            <button
                class="dynamic__button dynamic__stretch--width"
                (click)="openAccountOverlay()">
                Meine Bestellungen
            </button>
        </div>
        <form *ngIf="!this.successfulRequest"
              class="register__form"
              [formGroup]="this.formGroup">
            <div class="register__form__field"
                 *ngFor="let control of this.formModel">
                <div class="dynamic__text--xs">
                    {{ control.labelText }}
                </div>
                <input
                    *ngIf="isInput(control)"
                    class="register__form__field__input register__form__field__input--invalid dynamic__text--xs"
                    [type]="control.inputType"
                    [formControlName]="control.name"
                    [placeholder]="control.selectPlaceholder ?? ''"
                    [class.register__form__field__input--invalid]="
                        control.control.invalid &&
                        (control.control.dirty || control.control.touched)
                    " />
                <div class="register__form__field__error dynamic__text--xxs">
                <span
                    *ngIf="control.control.invalid &&
                        (control.control.dirty || control.control.touched) &&
                        isInput(control)">
                    {{ getErrorMessage(control.control) }}
                </span>
                </div>
            </div>

            <button
                class="dynamic__button dynamic__stretch--width"
                (click)="onFormSubmit()">
                Register
            </button>
        </form>
    </div>
</ng-container>
