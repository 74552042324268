import { Component } from '@angular/core';
import { OrderService } from '../../services/order/order.service';
import { Router } from '@angular/router';
import { Order } from '../../lib/interfaces/Order';

@Component({
    selector: 'app-order-confirm',
    templateUrl: './order-confirm.component.html',
    styleUrls: ['./order-confirm.component.scss'],
})
export class OrderConfirmComponent {
    constructor(
        private orderService: OrderService,
        private router: Router,
    ) {
        if (!orderService.order.orderProducts) {
            void this.router.navigateByUrl('/bestellen');
        }

        this.orderService.order = {} as Order;
    }
}
