import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import {
    DynamoFormComponent,
    DynamoFormControl,
    DynamoFormControlType,
    DynamoListMenuIconLibrary,
    DynamoListMenuItem,
    DynamoSelectOption,
} from '@skillgmbh/dynamo';
import { FormControl } from '@ngneat/reactive-forms';
import { Validators } from '@angular/forms';
import { Customer } from '../../lib/interfaces/Customer';
import { OrderService } from '../../services/order/order.service';
import { FixedOrderTime } from '../../lib/interfaces/FixedOrderTime';
import { Service } from '../../lib/interfaces/Service';
import { Router } from '@angular/router';
import { Event } from '../../lib/interfaces/Event';
import { OrderType } from '../../lib/enums/OrderType';
import { PaymentType } from '../../lib/enums/PaymentType';
import { CustomerService } from '../../services/customer/customer.service';
import { ServiceService } from '../../services/service/service.service';
import { catchError, EMPTY, filter, Observable, of, take } from 'rxjs';
import { CustomerAccount } from '../../lib/interfaces/CustomerAccount';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonService } from '../../services/common/common.service';
import { ExtendedDynamoFormControl } from '../dynamic-form/dynamic-form.component';

export interface CustomerInfoFormData {
    note: string;
    takeawayTime: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

@Component({
    selector: 'app-customer-info',
    templateUrl: './customer-info.component.html',
    styleUrls: ['./customer-info.component.scss'],
})
export class CustomerInfoComponent implements AfterViewInit {
    @ViewChild('customerInfoForm', { static: false }) customerInfoForm: DynamoFormComponent = {} as DynamoFormComponent;

    paymentType: PaymentType = PaymentType.ON_SPOT;

    formModel: ExtendedDynamoFormControl[] = [];
    formInterestListMenu: DynamoListMenuItem[] = [
        {
            key: 'TAKEAWAY',
            name: 'Zum Mitnehmen',
            icon: {
                type: 'takeaway',
                library: DynamoListMenuIconLibrary.DYNAMO,
            },
        },
        {
            key: 'ON_SITE',
            name: 'Vor Ort genießen',
            icon: {
                type: 'on_site',
                library: DynamoListMenuIconLibrary.DYNAMO,
            },
        },
    ];

    formTextArea: DynamoFormControl = {
        name: 'note',
        control: new FormControl(''),
        formControlType: DynamoFormControlType.INPUT,
        selectPlaceholder: 'Anmerkung zur Bestellung',
    };

    showForm: boolean = true;
    service: Service = {} as Service;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private orderService: OrderService,
        private customerService: CustomerService,
        private serviceService: ServiceService,
        private router: Router,
        private commonService: CommonService,
    ) {}

    ngAfterViewInit(): void {
        if (!this.orderService.order.orderProducts) {
            void this.router.navigateByUrl('/overview');
            return;
        }

        const takeawayTimeSelectOptions: DynamoSelectOption[] = [];

        if (!this.orderService.order.event) {
            this.orderService.orderTimes$.subscribe((orderTimes: FixedOrderTime[]) => {
                for (const orderTime of orderTimes) {
                    const orderTimeMinuteString = orderTime.minute < 10 ? orderTime.minute + '0' : orderTime.minute;
                    takeawayTimeSelectOptions.push({
                        key: JSON.stringify(orderTime),
                        text: `${orderTime.hour}:${orderTimeMinuteString}`,
                        active: true,
                    } as DynamoSelectOption);
                }
            });
        } else {
            for (const orderTime of this.orderService.order.event.fixedOrderTimes) {
                const orderTimeMinuteString = orderTime.minute < 10 ? orderTime.minute + '0' : orderTime.minute;
                takeawayTimeSelectOptions.push({
                    key: JSON.stringify(orderTime),
                    text: `${orderTime.hour}:${orderTimeMinuteString}`,
                    active: true,
                } as DynamoSelectOption);
            }
        }

        this.formModel = [
            {
                name: 'firstName',
                control: new FormControl(
                    this.customerService.customer ? this.customerService.customer.customer.firstName : '',
                    [Validators.required, Validators.minLength(2)],
                ),
                formControlType: DynamoFormControlType.INPUT,
                labelText: 'Vorname *',
            },
            {
                name: 'lastName',
                control: new FormControl(
                    this.customerService.customer ? this.customerService.customer.customer.lastName : '',
                    [Validators.required, Validators.minLength(2)],
                ),
                formControlType: DynamoFormControlType.INPUT,
                labelText: 'Nachname *',
            },
            {
                name: 'email',
                control: new FormControl(this.customerService.customer ? this.customerService.customer.email : '', [
                    Validators.required,
                    Validators.email,
                ]),
                inputType: 'mail',
                formControlType: DynamoFormControlType.INPUT,
                labelText: 'E-Mail *',
            },
            {
                name: 'phone',
                control: new FormControl(
                    this.customerService.customer ? this.customerService.customer.customer.phone : '',
                    [Validators.required, Validators.pattern(/^\+?[0-9\s\-().]+$/)],
                ),
                inputType: 'telephone',
                formControlType: DynamoFormControlType.INPUT,
                labelText: 'Telefonnummer *',
            },
            {
                name: 'takeawayTime',
                control: new FormControl(takeawayTimeSelectOptions[0].key, [Validators.required]),
                formControlType: DynamoFormControlType.SELECT,
                selectOptions: takeawayTimeSelectOptions,
                labelText: 'Abholzeit *',
                full: true,
            },
        ];

        this.changeDetector.detectChanges();
    }

    submit(formGroup: CustomerInfoFormData, orderTypeMenuItem: DynamoListMenuItem): void {
        this.customerService
            .getByAuthentication()
            .pipe(take(1))
            .pipe(
                catchError((error: HttpErrorResponse): Observable<any> => {
                    // When unauthorized the CustomerAccount doesn't exist & we set the customer attribute.
                    if (error.status !== 401 && error.status !== 403) return of(EMPTY);

                    this.orderService.order.customer = this.Customer(formGroup);

                    this.prepareOrder(formGroup, orderTypeMenuItem);

                    return of(null);
                }),
            )
            .pipe(filter(Boolean))
            .subscribe((customerAccount: CustomerAccount): void => {
                this.orderService.order.customer = this.Customer(formGroup);
                this.orderService.order.customerAccount = customerAccount;

                this.prepareOrder(formGroup, orderTypeMenuItem);
            });
    }

    prepareOrder(formGroupData: CustomerInfoFormData, orderTypeMenuItem: DynamoListMenuItem): void {
        this.orderService.order.takeawayTime = JSON.parse(formGroupData.takeawayTime) as FixedOrderTime;
        this.orderService.order.note = formGroupData.note;

        this.showForm = false;

        this.orderService.order.event = { id: this.orderService.order.event?.id } as Event;
        this.orderService.order.service = { uid: this.serviceService.fetchServiceUIDByLocation() } as Service;

        this.orderService.order.type = OrderType[orderTypeMenuItem.key as keyof typeof OrderType];
        this.orderService.order.paymentType = this.paymentType;

        this.orderService.submitOrder(this.orderService.order).subscribe((confirmationObject: object): void => {
            if (this.orderService.order.paymentType === PaymentType.STRIPE) {
                if (this.commonService.IsPlatformBrowser()) {
                    window.open((confirmationObject as { url: string }).url);
                }
            } else if (this.orderService.order.paymentType === PaymentType.PAYPAL) {
                if (this.commonService.IsPlatformBrowser()) {
                    window.open(
                        'https://www.paypal.com/checkoutnow?token=' +
                            (
                                confirmationObject as {
                                    token: string;
                                }
                            ).token,
                    );
                }
            } else {
                void this.router.navigateByUrl('/confirm/success');
            }
        });
    }

    Customer(formGroupData: CustomerInfoFormData): Customer {
        return {
            firstName: formGroupData.firstName,
            lastName: formGroupData.lastName,
            email: formGroupData.email,
            phone: formGroupData.phone,
        } as Customer;
    }
}
