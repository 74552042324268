import { inject, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { DynamoHttpService } from '@skillgmbh/dynamo';
import { CustomerAccount } from '../../lib/interfaces/CustomerAccount';
import { Observable } from 'rxjs';
import { Address } from '../../lib/interfaces/Address';
import { OverlayService } from '../overlay/overlay.service';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    customer?: CustomerAccount;

    private email: string = '';

    private httpService: DynamoHttpService = inject(DynamoHttpService);
    private overlayService: OverlayService = inject(OverlayService);

    constructor(@Inject(PLATFORM_ID) private platformId: string) {}

    register(customer: CustomerAccount): Observable<CustomerAccount> {
        return this.httpService.post<CustomerAccount>('service-proxy', 'customer', 'register', customer);
    }

    exists(customer: CustomerAccount): Observable<boolean> {
        return this.httpService.post<boolean>('service-proxy', 'customer', 'exists', customer);
    }

    login(customer: CustomerAccount): Observable<string> {
        this.logout();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.httpService.postExpectResponseType('service-proxy', 'customer', 'login', customer, 'text');
    }

    getByAuthentication(): Observable<CustomerAccount> {
        return this.httpService.get<CustomerAccount>('service-proxy', 'customer', 'get');
    }

    authenticate(token?: string): void {
        if (token) this.saveToken(token);
        this.getByAuthentication().subscribe({
            next: (customerAccount: CustomerAccount): void => {
                if (!customerAccount) return;
                this.customer = customerAccount;
            },
        });
    }

    logout(): void {
        this.removeToken();
        this.customer = undefined;
        this.overlayService.close();
    }

    saveToken(token: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('auth_tkn', token);
            this.getByAuthentication().subscribe((customer: CustomerAccount) => {
                this.customer = customer;
            });
        }
    }

    removeToken(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('auth_tkn', '');
        }
    }

    getToken(): string | null {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem('auth_tkn');
        }
        return null;
    }

    disableAccount(): Observable<unknown> {
        return this.httpService.post<unknown>('service-proxy', 'customer', 'disable', {});
    }

    updateAccount(customerAccount: CustomerAccount): Observable<CustomerAccount> {
        return this.httpService.post<CustomerAccount>('service-proxy', 'customer', 'update', customerAccount);
    }

    addAddress(address: Address): Observable<CustomerAccount> {
        return this.httpService.post<CustomerAccount>('service-proxy', 'customer', 'add/address', address);
    }

    removeAddress(address: Address): Observable<CustomerAccount> {
        return this.httpService.post<CustomerAccount>('service-proxy', 'customer', 'remove/address', address);
    }

    changePassword(param: { oldPassword: string; newPassword: string }): Observable<unknown> {
        return this.httpService.post<void>('service-proxy', 'customer', 'update/password', param);
    }

    setEmail(email: string): void {
        this.email = email;
    }

    getEmail(): string {
        return this.email;
    }

    getFullName(): string {
        if (!this.customer) return '';

        return `${this.customer.customer.firstName} ${this.customer.customer.lastName}`;
    }
}
