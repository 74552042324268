import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-service-inline-info',
    templateUrl: './service-inline-info.component.html',
    styleUrls: ['./service-inline-info.component.scss'],
})
export class ServiceInlineInfoComponent {
    @Input() gap: string = '1rem';
}
