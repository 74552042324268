import { AfterViewChecked, Directive, ElementRef, HostListener, inject, Input, Renderer2 } from '@angular/core';
import { CommonService } from '../services/common/common.service';

@Directive({
    selector: '[responsiveVisibility]',
    standalone: true,
})
export class ResponsiveVisibilityDirective implements AfterViewChecked {
    @Input() screenWidthTriggerPx: number = 840;
    @Input() showWhen: 'below' | 'above' = 'below';

    private commonService: CommonService = inject(CommonService);
    private el: ElementRef = inject(ElementRef);
    private renderer: Renderer2 = inject(Renderer2);

    ngAfterViewChecked(): void {
        this.toggleVisibility();
    }

    @HostListener('window:resize')
    onResize(): void {
        this.toggleVisibility();
    }

    private toggleVisibility(): void {
        if (!this.commonService.IsPlatformBrowser()) return;

        const width: number = window.innerWidth;

        if (width <= this.screenWidthTriggerPx) {
            this.showWhen === 'below'
                ? this.renderer.removeClass(this.el.nativeElement, 'dynamic__display--none')
                : this.renderer.addClass(this.el.nativeElement, 'dynamic__display--none');
        } else {
            this.showWhen === 'below'
                ? this.renderer.addClass(this.el.nativeElement, 'dynamic__display--none')
                : this.renderer.removeClass(this.el.nativeElement, 'dynamic__display--none');
        }
    }
}
