<ng-container *ngIf="!!service">
<!--    <div class="element-order-header__view">-->
<!--            <span fxLayoutAlign="center center"-->
<!--                  class="element-order-header__branding&#45;&#45;logo">-->
<!--                <img class="element-order-header__branding&#45;&#45;logo-image"-->
<!--                     src="assets/logos/rettensteiner-logo-gold-transparent.png">-->
<!--           </span>-->
<!--        <span class="dynamic__title&#45;&#45;sm dynamic__text&#45;&#45;white dynamic__text&#45;&#45;bold">{{ service.name }}</span>-->
<!--    </div>-->
<!--    <div class="element-order-header__inline-info">-->
<!--        <app-service-inline-info gap="2rem"></app-service-inline-info>-->
<!--    </div>-->
</ng-container>
