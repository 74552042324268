<div *ngIf="!!customerOrder?.service" class="customer-order-history-item__header">
    <img class="customer-order-history-item__header__logo"
         src="https://service-proxy.skillagentur.com/attributes/get/preview/logo/{{customerOrder.service?.id}}">
    <span class="customer-order-history-item__header__service-name dynamic__text--sm">
        {{customerOrder.service?.name}}
    </span>
</div>

<div class="customer-order-history-item__view"
     *ngIf="!!customerOrder" >
    <span class="dynamic__text--xs">
        Bestellt am {{customerOrder.creationTimestamp | date:'dd.MM.yyyy'}} um {{customerOrder.creationTimestamp | date:'HH:MM'}}
    </span>
    <span class="dynamic__text--xs">
        {{ customerOrder.paymentType === 'ON_SPOT' ? 'Barzahlung' : 'Online bezahlt' }}
    </span>
</div>
