import { Component, Input } from '@angular/core';
import { Service } from '../../lib/interfaces/Service';

@Component({
    selector: 'app-element-order-header',
    templateUrl: './element-order-header.component.html',
    styleUrls: ['./element-order-header.component.scss'],
})
export class ElementOrderHeaderComponent {
    @Input() service: Service = {} as Service;
}
