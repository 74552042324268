<div class="customer-dashboard__view">
    <span class="dynamic__button" (click)="customerService.logout();">Abmelden</span>
    <dynamo-list-menu
        class="customer-dashboard__list"
        listMenuLayout="row"
        defaultOption="order-history"
        [enforceSelection]="true"
        [listMenuConfig]="{
                listMenuOptions: [
                    {
                        key: 'order-history',
                        name: 'Bestellungen',
                    },
                    {
                        key: 'account',
                        name: 'Mein Konto',
                    },
                ],
            }"
    (selectedOptionChange)="setSelectedDashboardMenuKey($event)">
    </dynamo-list-menu>
    @if(selectedDashboardMenuKey === 'order-history') {
        <app-customer-order-history></app-customer-order-history>
    }
</div>
