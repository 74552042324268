import { Component, inject } from '@angular/core';
import { FormBuilder } from '@ngneat/reactive-forms';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerService } from '../../services/customer/customer.service';
import { CustomerAccount } from '../../lib/interfaces/CustomerAccount';
import { OverlayService } from '../../services/overlay/overlay.service';
import { RegisterComponent } from '../register/register.component';
import { CustomerDashboardComponent } from '../customer-dashboard/customer-dashboard.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    customerService: CustomerService = inject(CustomerService);
    formBuilder: FormBuilder = inject(FormBuilder);
    overlayService: OverlayService = inject(OverlayService);

    loginForm: UntypedFormGroup;
    emailConfirmed: boolean = false;

    constructor() {
        if (this.customerService.customer) {
            this.overlayService.updateInputs({
                title: this.customerService.getFullName(),
                iconType: '',
                description: '',
            });
            this.overlayService.updateComponent(CustomerDashboardComponent);
        }

        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
        });
    }

    buildCustomerAccountObject(): CustomerAccount {
        return {
            email: this.loginForm.controls['email']?.value as string,
            password: this.loginForm.controls['password']?.value as string,
        } as CustomerAccount;
    }

    onSubmit(): void {
        if (this.loginForm.invalid) return;
        this.customerService.login(this.buildCustomerAccountObject()).subscribe({
            next: (token: string): void => {
                if (token) this.customerService.saveToken(token);
                this.customerService.getByAuthentication().subscribe({
                    next: (customerAccount: CustomerAccount): void => {
                        if (!customerAccount) return;
                        this.customerService.customer = customerAccount;
                        this.overlayService.updateInputs({
                            title: this.customerService.getFullName(),
                            iconType: '',
                            description: '',
                        });
                        this.overlayService.updateComponent(CustomerDashboardComponent);
                    },
                });
            },
            error: (): void => {
                this.loginForm.controls['password'].setErrors({ serverError: 'unauthorized' });
            },
        });
    }

    confirmEmail(): void {
        this.customerService.exists(this.buildCustomerAccountObject()).subscribe({
            next: (exists: boolean): void => {
                if (exists) {
                    this.emailConfirmed = true;
                } else {
                    this.customerService.setEmail(this.loginForm.controls['email'].value as string);
                    this.overlayService.updateInputs({ title: 'Registrieren', iconType: '', description: '' });
                    this.overlayService.updateComponent(RegisterComponent);
                }
            },
        });
    }
}
