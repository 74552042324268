<ng-container *ngIf="!!service">
    <div class="service-overview-item__view">
        <div class="service-overview-item__information">
            <div class="service-overview-item__information__header">
                <span class="dynamic__text dynamic__text--bold">{{ service.name }}</span>
                <span class="service-overview-item__information__description dynamic__text--xs">Traditionelle Küche, Metzgerei</span>
            </div>
            <app-service-inline-info class="service-overview-item__information__inline"></app-service-inline-info>
        </div>
        <dynamo-image
            class="service-overview-item__image"
            width="15rem"
            height="100%"
            backgroundSize="cover"
            showWhen="above"
            source="assets/background2.jpg"
            [screenWidthTriggerPx]="650"
            dynamoImageSource
            responsiveVisibility>
        </dynamo-image>
        <dynamo-image
            class="service-overview-item__image"
            width="100%"
            height="9rem"
            backgroundSize="cover"
            showWhen="below"
            source="assets/background2.jpg"
            [screenWidthTriggerPx]="650"
            dynamoImageSource
            responsiveVisibility>
        </dynamo-image>
    </div>
</ng-container>
