<form class="dynamic-form__view" [formGroup]="this.formGroup" (keyup.enter)=onFormSubmit()>
    <div *ngIf="this.listMenuOptions.length" class="dynamic-form__list">
        <dynamo-list-menu
            [defaultOption]="listMenuOptions[0].key"
            [enforceSelection]="true"
            [listMenuLayout]="this.commonService.isMobile(450) ? 'column' : 'row'"
            [listMenuConfig]="{ listMenuOptions: this.listMenuOptions }"
            (selectedOptionChange)="selectedMenuOption = $event">
        </dynamo-list-menu>
    </div>
    <span class="dynamic-form__personal-info"
          [class.dynamic-form__personal-info--mobile]="commonService.isMobile()">
        <ng-container *ngFor="let control of this.formControls">
            <div class="dynamic-form__field"
                 [class.dynamic-form__field__extended]="control.full || commonService.isMobile(700)">
                <div class="dynamic-form__field__label dynamic__text--xs">
                 {{ control.labelText }}
                </div>
                <input
                    *ngIf="isInput(control)"
                    class="dynamic-form__field__input dynamic__text-xs"
                    [formControlName]="control.name"
                    [placeholder]="control.selectPlaceholder ? control.selectPlaceholder : ''"
                    [class.dynamic-form__field--invalid]="
                         this.formGroup.controls[control.name]?.dirty &&
                         this.formGroup.controls[control.name]?.invalid
                     " />
                <select
                    *ngIf="!isInput(control)"
                    class="dynamic-form__field__select dynamic__text-xs"
                    [formControlName]="control.name"
                    [class.dynamic-form__field--invalid]="
                            this.formGroup.controls[control.name]?.dirty &&
                            this.formGroup.controls[control.name]?.invalid">
                    <option
                        class="dynamic-form__field__input dynamic__text-xs"
                        *ngFor="let option of control.selectOptions"
                        [value]="option.key"
                        [disabled]="!option.active">
                             {{ option.text }}
                    </option>
                </select>
                <div class="dynamic-form__field__input--error dynamic__text--xxs">
                     <span
                         *ngIf="control.control.invalid &&
                             (control.control.dirty || control.control.touched) &&
                             isInput(control)">{{ this.getErrorMessage(control.control) }}
                     </span>
                 </div>
            </div>
        </ng-container>

        <div *ngIf="this.textAreaControl" class="dynamic-form__field--message dynamic-form__field__extended">
            <textarea
                class="dynamic-form__field__input dynamic-form__field__input--message dynamic__text-xs"
                [class.dynamic-form__field__input--message--mobile]="commonService.isMobile()"
                [formControlName]="this.textAreaControl.name"
                [placeholder]="this.textAreaControl?.selectPlaceholder"
                (input)="adjustTextAreaHeight($event)">
            </textarea>
        </div>
    </span>

    <button class="dynamic-form__submit dynamic__button" (click)="onFormSubmit()">
        {{ this.submitButtonText }}
    </button>
</form>
