<ng-container>
  <span fxLayout="column"
        fxLayoutAlign="start center"
        class="dynamic__stretch--view dynamic__padding--md">
              <div class="order-confirm__header">
                <img class="order-confirm__image" src="assets/check-art.png">
                  <span class="order-confirm__heading">Alles erledigt</span>
                <span class="order-confirm__title dynamic__title--md">Deine Bestellung wurde weitergeleitet<br>Bitte überprüfe deine E-Mails</span>
        </div>
  </span>
</ng-container>
