@if (
    ((orderService.serviceEnabled$ | async) && (categoryService.categoryCollection$ | async)?.length) ||
    event?.orderEnabled
) {
    <div class="order__view dynamic__stretch--width">
        <div class="order__order-area">
            <div class="order__header dynamic__stretch--width">
                <div
                    class="order__header__image"
                    [style.backgroundImage]="
                        'url(https://service-proxy.skillagentur.com/attributes/get/preview/banner/1)'
                    "></div>
            </div>

            <div class="order__list dynamic__stretch--width">
                @for (category of categoryService.categoryCollection$ | async; track category) {
                    <div class="order__list__category">
                        <span class="order__list__category-title dynamic__title--sm dynamic__text--bold">
                            {{ category.name }}
                        </span>
                        <div class="order__list__category__products dynamic__stretch--width">
                            @for (product of category.products; track product) {
                                <div
                                    class="order__list__category__products__product"
                                    (click)="addProductToCheckout(product)">
                                    <span fxLayout="column" fxLayoutGap="1rem">
                                        <span fxLayout="column" fxLayoutGap=".3rem">
                                            <span class="dynamic__text--sm dynamic__text--bold">{{
                                                commonService.removeDoubleSpacesFromText(product.name)
                                            }}</span>
                                            @if (!!product.description) {
                                                <span
                                                    class="dynamic__text--xs dynamic__text--accent order__list__category__products__product__description"
                                                    >{{
                                                        commonService.removeDoubleSpacesFromText(product.description)
                                                    }}</span
                                                >
                                            }
                                        </span>
                                        <span class="dynamic__text--xs dynamic__text--bold">
                                            {{ product.price?.toFixed(2)?.replace('.', ',') }} €
                                        </span>
                                    </span>
                                    <span class="order__list__category__products__product__add-to-cart-button">
                                        <dynamo-icon
                                            class="order__list__category__products__product__add-to-cart-button__icon"
                                            dynamoIconType="shopping-bag"
                                            manualSize="1.5rem">
                                        </dynamo-icon>
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>

        @if (!commonService.isMobile(1000)) {
            <app-checkout #checkout class="order__checkout-area" [service]="service"></app-checkout>
        }

        @if (commonService.isMobile(mobileBreakPx)) {
            <span class="order__checkout-button-mobile dynamic__tile">
                <span class="order__button" routerLink="/checkout/{{ service.uid }}" [@shake]="showAnimation">
                    <span class="order__button__icon dynamic__title--sm--xs">
                        <mat-icon>shopping_cart</mat-icon>
                        <span>Zum Warenkorb ({{ calculateProductCountCheckout() }} ausgewählt)</span>
                    </span>
                </span>
            </span>
        }
    </div>
}

@if (!(orderService.serviceEnabled$ | async) && event !== null && event?.orderEnabled !== true) {
    <span class="order__info dynamic__stretch dynamic__padding--lg">
        <span class="order__info__tile dynamic__tile">
            <dynamo-icon-and-text iconType="schedule" iconColor="#54656c" text=""> </dynamo-icon-and-text>
            <span class="dynamic__text" style="color: #54656c !important; text-align: center"
                >Vorbestellungen sind von Montag bis Freitag (00:00 - 11:00 Uhr) möglich.<br /><br />Gerne können Sie
                sich Ihr Menü auch ohne Reservierung abholen.</span
            >
        </span>
    </span>
}
