<div class="element-overlay-base__view">
  <div class="element-overlay-base__header">
    <div class="element-overlay-base__header__caption">
      <div class="element-overlay-base__header__caption__heading">
        <dynamo-icon [dynamoIconType]="iconType" [manualSize]="iconSize"></dynamo-icon>
        <span class="dynamic__text">{{ title }}</span>
      </div>
      <mat-icon
        class="element-overlay-base__header__caption__close-button"
        (click)="close.emit()">
        close
      </mat-icon>
    </div>
    <span *ngIf="description" class="dynamic__text--xs">{{ description }}</span>

  </div>
  <ng-content></ng-content>
</div>


