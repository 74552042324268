import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Category } from '../../lib/interfaces/Category';
import { DynamoHttpService } from '@skillgmbh/dynamo';
import { Product } from '../../lib/interfaces/Product';

@Injectable({
    providedIn: 'root',
})
export class CategoryService implements OnDestroy {
    private destroyed$: Subject<void>;

    private categoryCollectionSubject$: BehaviorSubject<Category[]>;
    categoryCollection$: Observable<Category[]>;

    constructor(private httpService: DynamoHttpService) {
        this.destroyed$ = new Subject<void>();

        this.categoryCollectionSubject$ = new BehaviorSubject<Category[]>([] as Category[]);
        this.categoryCollection$ = this.categoryCollectionSubject$.asObservable();
    }

    ngOnDestroy(): void {
        this.categoryCollectionSubject$.complete();

        this.destroyed$.next();
        this.destroyed$.complete();
    }

    fetchCategories(serviceId: number): void {
        this.httpService
            .post<Category[]>('service-proxy', 'category', 'get/service/customer', { id: serviceId })
            .subscribe((categoryCollection: Category[]) => {
                categoryCollection.forEach((category: Category) => {
                    this.httpService
                        .post<Product[]>('service-proxy', 'product', 'get/category/customer', category)
                        .subscribe((products: Product[]) => {
                            category.products = products;
                            this.categoryCollectionSubject$.next(categoryCollection);
                        });
                });
            });
    }
}
