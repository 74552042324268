import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Service } from '../../lib/interfaces/Service';
import { DynamoHttpService } from '@skillgmbh/dynamo';
import { Company } from '../../lib/interfaces/Company';
import { CommonService } from '../common/common.service';
@Injectable({
    providedIn: 'root',
})
export class ServiceService {
    private commonService: CommonService = inject(CommonService);
    private httpService: DynamoHttpService = inject(DynamoHttpService);

    fetchCollectionByCompany(company: Company): Observable<Service[]> {
        return this.httpService.post('service-proxy', 'service', 'get/company/customer', company);
    }

    fetchById(serviceId: number): Observable<Service> {
        return this.httpService.get<Service>('service-proxy', 'service', `get?id=${serviceId}`);
    }

    fetchByUID(serviceUID: string): Observable<Service> {
        return this.httpService.get<Service>('service-proxy', 'service', `get/customer/uid?uid=${serviceUID}`);
    }

    fetchAllUIDs(): Observable<string[]> {
        return this.httpService.get<string[]>('service-proxy', 'service', 'get/customer/all/uids');
    }

    // Used for fetching the service uid in the info & checkout components from the route.
    fetchServiceUIDByLocation(): string | undefined {
        if (!this.commonService.IsPlatformBrowser()) return undefined;

        const href: string[] = location.href.split('/');

        if (href.length < 4) return undefined;

        return href[4];
    }
}
