<ng-container>
    <div fxLayout="row"
         fxLayoutAlign="space-between center"
         class="element-footer__view">
        <a fxLayoutAlign="start center" href="https://www.skillagentur.com" target="_blank">
            <img class="element-footer__logo" src="assets/logos/skillagentur-logo-new-black-2.png">
        </a>
        <span class="dynamic__text--xs" style="cursor: pointer" routerLink="/impressum">Impressum & Datenschutz</span>
    </div>
</ng-container>
