import { Component, inject } from '@angular/core';
import { Order } from '../../lib/interfaces/Order';
import { OrderService } from '../../services/order/order.service';
import { take } from 'rxjs';

@Component({
    selector: 'app-customer-order-history',
    templateUrl: './customer-order-history.component.html',
    styleUrls: ['./customer-order-history.component.scss'],
})
export class CustomerOrderHistoryComponent {
    protected customerOrders?: Order[];

    private orderService: OrderService = inject(OrderService);

    constructor() {
        this.orderService
            .getCustomerOrdersByAuth()
            .pipe(take(1))
            .subscribe((customerOrders: Order[]): void => {
                this.customerOrders = customerOrders;
            });
    }
}
