<ng-container>
  <div fxLayout="row"
       fxLayoutAlign="start center"
       class="element-header__view">
        <span fxLayout="row"
              fxLayoutAlign="start center">
                    <a href="https://www.skillagentur.com" target="_blank">
            <img class="element-header__logo"
                 src="../../../assets/logos/skillagentur-logo-new-black-2.png">
        </a>
        <mat-divider [vertical]="true"
                     style="color: black; margin-right: 1rem; width: 5px; height: 2rem; padding-left: .5rem !important;"></mat-divider>

        </span>
    <div fxLayoutGap="1rem">
      <span class="dynamic__title--xxs" style="cursor: pointer" routerLink="/management/overview">Overview</span>
      <span class="dynamic__title--xxs" style="cursor: pointer" routerLink="/management/settings">Settings</span>
    </div>
    <!--span fxLayoutAlign="center center"
          class="element-header__button">Benutzerkonto erstellen</span-->
  </div>
</ng-container>
