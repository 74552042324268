import { inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Product } from '../../lib/interfaces/Product';
import { DynamoHttpService } from '@skillgmbh/dynamo';
import { Service } from '../../lib/interfaces/Service';
import { Order } from '../../lib/interfaces/Order';
import { FixedOrderTime } from '../../lib/interfaces/FixedOrderTime';
import { OrderProduct } from '../../lib/interfaces/OrderProduct';

@Injectable({
    providedIn: 'root',
})
export class OrderService implements OnDestroy {
    private destroyed$: Subject<void> = new Subject<void>();

    private serviceEnabledSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    serviceEnabled$: Observable<boolean> = this.serviceEnabledSubject$.asObservable();

    private availableProductsSubject$: BehaviorSubject<Product[]> = new BehaviorSubject<Product[]>([] as Product[]);
    availableProducts$: Observable<Product[]> = this.availableProductsSubject$.asObservable();

    private orderTimeCollectionSubject$: BehaviorSubject<FixedOrderTime[]> = new BehaviorSubject<FixedOrderTime[]>(
        [] as FixedOrderTime[],
    );
    orderTimes$: Observable<FixedOrderTime[]> = this.orderTimeCollectionSubject$.asObservable();

    public productMarkedForOptionSelectionSubject$: BehaviorSubject<Product | undefined> = new BehaviorSubject<
        Product | undefined
    >(undefined);
    productMarkedForOptionSelection$: Observable<Product | undefined> =
        this.productMarkedForOptionSelectionSubject$.asObservable();

    public order: Order = {} as Order;

    private httpService: DynamoHttpService = inject(DynamoHttpService);

    constructor() {
        this.fetchFixedOrderTimes();
    }

    ngOnDestroy(): void {
        this.serviceEnabledSubject$.complete();
        this.availableProductsSubject$.complete();
        this.orderTimeCollectionSubject$.complete();
        this.productMarkedForOptionSelectionSubject$.complete();

        this.destroyed$.next();
        this.destroyed$.complete();
    }

    getCustomerOrdersByAuth(): Observable<Order[]> {
        return this.httpService.post<Order[]>('service-proxy', 'order', 'customer/auth', {});
    }

    fetchAvailableProducts(serviceUID: string): void {
        this.httpService
            .get<Service>('service-proxy', 'service', `get/customer/uid?uid=${serviceUID}`)
            .subscribe((service: Service) => {
                this.availableProductsSubject$.next(service.products);
                this.serviceEnabledSubject$.next(service.enabled);
            });
    }

    fetchFixedOrderTimes(): void {
        this.httpService
            .get<FixedOrderTime[]>('service-proxy', 'service', `get/hours?id=${1}`)
            .subscribe((orderTimes: FixedOrderTime[]) => {
                this.orderTimeCollectionSubject$.next(orderTimes);
            });
    }

    submitOrder(order: Order): Observable<object> {
        return this.httpService.post<object>('service-proxy', 'order', 'save/request', order);
    }

    registerOrderProduct(product: Product, options: Product[]): void {
        const orderProduct: OrderProduct = {
            product,
            options,
            quantity: 1,
        } as OrderProduct;

        const existingCandidates: OrderProduct[] = this.order.orderProducts.filter(
            (x: OrderProduct) =>
                x.product.id === orderProduct.product.id && (!x.options.length || x.options[0].id === options[0].id),
        );

        if (existingCandidates.length) {
            orderProduct.quantity = existingCandidates[0].quantity + 1;
            this.order.orderProducts = this.order.orderProducts.filter(
                (x: OrderProduct): boolean => x !== existingCandidates[0],
            );
        }

        this.order.orderProducts.push(orderProduct);
    }

    unregisterOrderProduct(orderProduct: OrderProduct): void {
        this.order.orderProducts = this.order.orderProducts.filter((x: OrderProduct): boolean => x !== orderProduct);
    }
}
