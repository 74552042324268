 <div class="checkout__view"
         fxLayout="column"
         [fxLayoutAlign]="router.isActive('/checkout', true) ? 'start center' : 'start start'"
         [class.dynamic__padding--sm]="router.isActive('/checkout', true)">
        <div fxLayout="column"
             fxLayoutGap="1rem"
             class="checkout__products">
            <div fxLayout="column"
                 fxLayoutGap="1rem"
                 class="dynamic__padding--sm">

                <div class="order-widget-checkout__no-selection"
                     *ngIf="!orderService.order.orderProducts?.length">
                        <span fxLayout="column" fxLayoutGap=".5rem">
                            <span
                                class="order-widget-checkout__no-selection__title dynamic__title--sm dynamic__text--center">Dein Teller<br>ist noch leer.</span>
                            <span
                                class="dynamic__text--sm dynamic__text--accent dynamic__text--center">Bitte wähle ein Gericht aus.</span>
                        </span>
                    <img class="order-widget-checkout__no-selection__image"
                         src="assets/empty-plate.png">
                </div>

                <div fxLayout="row"
                     fxLayoutGap="1rem"
                     class="order-widget-checkout__product dynamic__padding--xs"
                     *ngFor="let orderProduct of orderService.order.orderProducts">
                    <dynamo-select size="3rem"
                                   [value]="orderProduct.quantity"
                                   (valueChange)="orderProduct.quantity = $event"
                                   [selectItems]="[{value: '1'}, {value: '2'}, {value: '3'}, {value: '4'}, {value: '5'}, {value: '6'}, {value: '7'}, {value: '8'}, {value: '9'}, {value: '10'}]">
                    </dynamo-select>

                    <div fxLayout="column" fxLayoutGap=".5rem">
                            <span fxLayout="row"
                                  fxLayoutAlign="start start"
                                  fxLayoutGap=".5rem">
                                <span fxLayout="column" fxLayoutGap=".5rem">
                                    <span class="order-widget-checkout__product--name dynamic__text--sm dynamic__text--bold">{{commonService.removeDoubleSpacesFromText(orderProduct.product.name)}}
                                    </span>
                                    <span class="dynamic__text--xs dynamic__text--accent" *ngFor="let option of orderProduct.options">{{commonService.removeDoubleSpacesFromText(option.name) }} ({{ option.price.toFixed(2).replace('.', ',') }} €)
                                    </span>
                                    <span class="dynamic__text--xs dynamic__text--accent" *ngIf="!orderService.order.event?.priceDisabled">
                                        € {{ calculateOrderProductPrice(orderProduct).toFixed(2).replace('.', ',') }}
                                    </span>
                                </span>
                            </span>
                    </div>

                    <mat-icon class="order-widget-checkout__remove-button"
                              (click)="removeProductFromCheckout(orderProduct)">close
                    </mat-icon>
                </div>
            </div>
        </div>

        <span class="dynamic__divider--horizontal" *ngIf="orderService.order.orderProducts?.length"></span>

        <span fxLayout="column"
              fxLayoutAlign="start start"
              fxLayoutGap="1rem"
              class="checkout__button-area dynamic__width-min--full dynamic__padding--horizontal--sm"
              *ngIf="orderService.order.orderProducts?.length">

                <span class="dynamic__text--sm">
                    <span
                        *ngIf="!orderService.order.event?.priceDisabled">Zu bezahlen: € {{ fetchOrderTotal().replace('.', ',') }}</span><br>
                    <span class="dynamic__text--xs dynamic__text--accent">Bezahlung vor Ort</span>
                </span>

                <span fxLayout="row"
                      fxLayoutAlign="center center"
                      fxLayoutGap="0rem"
                      (click)="continueCheckout()"
                      class="order-widget-checkout__button dynamic__text--sm dynamic__width-min--full">
                    <span class="dynamic__title--xs">Fortfahren</span>
                </span>
            </span>
    </div>

