<div class="dynamic__padding--md"
     fxLayout="column"
     fxLayoutAlign="start start"
     fxLayoutGap="1rem">
    <span class="dynamic__title--sm">Impressum & Kontakt</span>
    <div class="interface-impressum-dynamic__text--sm"
         fxLayout="column"
         fxLayoutAlign="start start">
        <span class="dynamic__text--sm" style="font-weight: 600">SKILL OG</span>
        <span class="dynamic__text--sm">Wagrainerstraße 3b</span>
        <span class="dynamic__text--sm">5600 Sankt Johann im Pongau</span>
        <span class="dynamic__text--sm">Salzburg, Österreich</span><br><br>
        <span class="dynamic__text--sm">Firmenbuchnummer: 619494x</span>
    </div>
    <div class="interface-impressum-dynamic__text--sm"
         fxLayout="column"
         fxLayoutAlign="start start">
        <span class="dynamic__text--sm">Kontakt</span>
        <span class="dynamic__text--sm">Mail: info&#64;skillagentur.com</span>
        <span class="dynamic__text--sm">Tel.: +43 (0) 664 4578320</span>
    </div>
    <span class="dynamic__title--sm">Datenschutz</span>
    <div class="interface-datenschutz-dynamic__text--sm"
         fxLayout="column"
         fxLayoutAlign="start start">
    <span class="dynamic__text--sm">Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.<br>
      Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.<br> Als
      Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.<br>
      Wir sind jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach
      Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br>Verpflichtungen zur Entfernung oder
      Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.<br> Eine
      diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.<br>
      Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte entfernen.<br><br></span>
        <span class="dynamic__text--sm" style="font-weight: 600;">Haftung für Links auf Webseiten Dritter</span>
        <span class="dynamic__text--sm"><br>Unser Angebot enthält Links zu externen Websites.<br>Auf den Inhalt dieser externen Webseiten
      haben wir keinerlei Einfluss.<br>Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.<br>Für
      die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.<br>Die
      verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.<br>Rechtswidrige
      Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.<br>Eine permanente inhaltliche Kontrolle der
      verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar.<br>Bei
      Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.<br><br></span>
        <span class="dynamic__text--sm" style="font-weight: 600;">Urheberrecht</span>
        <span class="dynamic__text--sm"><br>Die Betreiber dieser Webseite sind bemüht, stets die Urheberrechte anderer zu beachten bzw.
      auf selbst erstellte sowie lizenzfreie Werke zurückzugreifen.<br>Die durch die Seitenbetreiber erstellten Inhalte
      und Werke auf dieser Webseite unterliegen dem Urheberrecht.<br>Beiträge Dritter sind als solche
      gekennzeichnet.<br>Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
      Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.<br>Downloads
      und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.<br><br></span>
        <span class="dynamic__text--sm" style="font-weight: 600;">Datenschutzerklärung</span>
        <span class="dynamic__text--sm"><br>Wir legen großen Wert auf den Schutz Ihrer Daten.<br>Um Sie in vollem Umfang über die
      Verwendung personenbezogener Daten zu informieren, bitten wir Sie die folgenden Datenschutzhinweise zur Kenntnis
      zu nehmen.<br><br></span>
        <span class="dynamic__text--sm" style="font-weight: 600;">Persönliche Daten</span>
        <span class="dynamic__text--sm"><br>Persönliche Daten, die Sie auf dieser Website elektronisch übermitteln, wie zum Beispiel
      Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben, werden von uns nur zum jeweils angegebenen Zweck
      verwendet, sicher verwahrt und nicht an Dritte weitergegeben.<br>Der Provider erhebt und speichert automatisch
      Informationen am Webserver wie verwendeter Browser, Betriebssystem, Verweisseite, IP-Adresse, Uhrzeit des Zugriffs
      usw.<br>Diese Daten können ohne Prüfung weiterer Datenquellen keinen bestimmten Personen zugeordnet werden und wir
      werten diese Daten auch nicht weiter aus solange keine rechtswidrige Nutzung unserer Webseite vorliegt.<br><br>
    </span>
        <span class="dynamic__text--sm" style="font-weight: 600;">Formulardaten und Kommentare</span>
        <span class="dynamic__text--sm"><br>Wenn Webseitenbesucher Kommentare oder Formulareinträge hinterlassen, werden die eingegebenen
      Daten und ihre IP-Adressen gespeichert.<br>Das erfolgt zur Sicherheit, falls jemand widerrechtliche Inhalte
      verfasst (Beleidigungen, links- oder rechtsextreme Propaganda, Hasspostings usw.).<br>In diesem Fall sind wir an
      der Identität des Verfassers interessiert.<br><br></span>
        <span class="dynamic__text--sm" style="font-weight: 600;">Cookies</span>
        <span class="dynamic__text--sm"><br>Cookies sind kleine Dateien, die es dieser Webseite ermöglichen auf dem Computer des
      Besuchers spezifische, auf den Nutzer bezogene Informationen zu speichern, während unsere Website besucht
      wird.<br>Cookies helfen uns dabei, die Nutzungshäufigkeit und die Anzahl der Nutzer unserer Internetseiten zu
      ermitteln, sowie unsere Angebote für Sie komfortabel und effizient zu gestalten.<br>Wir verwenden einerseits
      Session-Cookies, die ausschließlich für die Dauer Ihrer Nutzung unserer Website zwischengespeichert werden und zum
      anderen permanente Cookies, um Informationen über Besucher festzuhalten, die wiederholt auf unsere Website
      zugreifen.<br>Der Zweck des Einsatzes dieser Cookies besteht darin, eine optimale Benutzerführung anbieten zu
      können sowie Besucher wiederzuerkennen und bei wiederholter Nutzung eine möglichst attraktive Website und
      interessante Inhalte präsentieren zu können.<br>Der Inhalt eines permanenten Cookies beschränkt sich auf eine
      Identifikationsnummer.<br>Name, IP-Adresse usw. werden nicht gespeichert.<br>Eine Einzelprofilbildung über Ihr
      Nutzungsverhalten findet nicht statt.<br>Eine Nutzung unserer Angebote ist auch ohne Cookies möglich.<br>Sie
      können in Ihrem Browser das Speichern von Cookies deaktivieren, auf bestimmte Webseiten beschränken oder Ihren
      Webbrowser (Chrome, IE, Firefox,…) so einstellen, dass er sie benachrichtigt, sobald ein Cookie gesendet wird.<br>Sie
      können Cookies auch jederzeit von der Festplatte ihres PC löschen.<br>Bitte beachten Sie aber, dass Sie in diesem
      Fall mit einer eingeschränkten Darstellung der Seite und mit einer eingeschränkten Benutzerführung rechnen müssen.<br><br>
    </span>
        <span class="dynamic__text--sm" style="font-weight: 600;">Auskunftsrecht</span>
        <span class="dynamic__text--sm">Sie haben jederzeit das Recht auf Auskunft über die bezüglich Ihrer Person gespeicherten Daten,
      deren Herkunft und Empfänger sowie den Zweck der Speicherung</span>
    </div>

</div>
