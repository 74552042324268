import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(
        private router: Router,
        @Optional() @Inject(PLATFORM_ID) private platformId: string,
    ) {}

    IsPlatformBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    environment(): any {
        return environment;
    }

    navigate(route: string): void {
        void this.router.navigateByUrl(route);
    }

    isRouteActive(route: string): boolean {
        return this.router.isActive(route, true);
    }

    isMobile(maxPx = 840): boolean {
        if (!this.IsPlatformBrowser()) return false;

        return window.innerWidth <= maxPx;
    }

    removeDoubleSpacesFromText(text: string): string {
        return text.replace(/\s{2,}/g, ' ');
    }

    scrollToTop(): void {
        if (!this.IsPlatformBrowser()) return;

        document.documentElement.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }
}
