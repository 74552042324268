<ng-container *ngIf="this.orderService.productMarkedForOptionSelection$ | async as product">
    <div class="order-product-select-options__view">
            <mat-selection-list #optionSelection
                                class="order-product-select-options__list"
                                [multiple]="false">
                <mat-list-option *ngFor="let option of product.options"
                                 [value]="option.id"
                                 (click)="selectOption(product, option)"
                                 class="dynamic__text--sm">
                    {{option.name}} ({{option.price.toFixed(2).replace('.', ',')}} €)
                </mat-list-option>
            </mat-selection-list>
    </div>
</ng-container>
