import { Injectable } from '@angular/core';
import { DynamoHttpService } from '@skillgmbh/dynamo';
import { Observable, Subject } from 'rxjs';
import { Event } from '../../lib/interfaces/Event';
import { Company } from '../../lib/interfaces/Company';

@Injectable({
    providedIn: 'root',
})
export class EventService {
    private destroyed$: Subject<void>;

    constructor(private httpService: DynamoHttpService) {
        this.destroyed$ = new Subject<void>();
    }

    fetchEventById(eventId: number): Observable<Event> {
        return this.httpService.post<Event>('service-proxy', 'event', 'get/customer', { id: eventId } as Event);
    }

    fetchCollectionByCompany(company: Company): Observable<Event[]> {
        return this.httpService.post('service-proxy', 'event', 'get/customer', company);
    }
}
