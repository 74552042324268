import { ComponentRef, inject, Injectable, Injector, Type, ViewContainerRef } from '@angular/core';
import { ElementOverlayComponent } from '../../elements/element-overlay/element-overlay.component';
import { CommonService } from '../common/common.service';

@Injectable({
    providedIn: 'root',
})
export class OverlayService {
    injector: Injector = inject(Injector);

    overlayComponentRef?: ComponentRef<ElementOverlayComponent>;

    private commonService: CommonService = inject(CommonService);

    open<T>(
        viewContainerRef: ViewContainerRef,
        component: Type<T>,
        inputs: { title: string; iconType: string; description: string } = {
            title: '',
            iconType: '',
            description: '',
        },
    ): void {
        if (!this.commonService.IsPlatformBrowser()) return;

        this.overlayComponentRef = viewContainerRef.createComponent(ElementOverlayComponent, {
            injector: this.injector,
        });

        this.updateInputs(inputs);

        this.overlayComponentRef.instance.closeEvent.subscribe((): void => {
            this.destroyRef();
        });

        this.overlayComponentRef.instance.loadComponent(component);
    }

    updateComponent<T>(component: Type<T>, inputs?: { title: string; iconType: string; description: string }): void {
        if (!this.commonService.IsPlatformBrowser()) return;
        if (!this.overlayComponentRef) return;

        this.updateInputs(inputs);

        this.overlayComponentRef.instance.loadComponent(component);
    }

    close(): void {
        if (!this.commonService.IsPlatformBrowser()) return;

        if (this.overlayComponentRef) {
            this.overlayComponentRef.instance.close();
        }
    }

    destroyRef(): void {
        if (!this.commonService.IsPlatformBrowser()) return;

        if (this.overlayComponentRef) {
            this.overlayComponentRef.destroy();
        }
    }

    updateInputs(inputs?: { title: string; iconType: string; description: string }): void {
        if (!this.commonService.IsPlatformBrowser()) return;

        if (!this.overlayComponentRef || !inputs) return;

        this.overlayComponentRef.instance['title'] = inputs.title;
        this.overlayComponentRef.instance['iconType'] = inputs.iconType;
        this.overlayComponentRef.instance['description'] = inputs.description;
    }
}
