import { Component, HostListener, inject, OnDestroy, ViewContainerRef } from '@angular/core';
import { CustomerService } from '../../services/customer/customer.service';
import { CommonService } from '../../services/common/common.service';
import { Subject } from 'rxjs';
import { OverlayService } from '../../services/overlay/overlay.service';
import { LoginComponent } from '../../components/login/login.component';
import { OrderService } from '../../services/order/order.service';
import { CustomerAccount } from '../../lib/interfaces/CustomerAccount';
import { ServiceService } from '../../services/service/service.service';

@Component({
    selector: 'app-element-header',
    templateUrl: './element-header.component.html',
    styleUrls: ['./element-header.component.scss'],
})
export class ElementHeaderComponent implements OnDestroy {
    protected customerAccount?: CustomerAccount;
    protected isWindowScrolled: boolean = false;

    protected commonService: CommonService = inject(CommonService);
    protected customerService: CustomerService = inject(CustomerService);
    protected serviceService: ServiceService = inject(ServiceService);
    protected orderService: OrderService = inject(OrderService);

    private destroyed$: Subject<void> = new Subject<void>();

    overlayService: OverlayService = inject(OverlayService);
    viewContainerRef: ViewContainerRef = inject(ViewContainerRef);

    @HostListener('window:scroll')
    protected onWindowScroll(): void {
        const verticalOffset: number =
            window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        this.isWindowScrolled = verticalOffset > 0;
    }

    constructor() {
        if (this.customerService.getToken()) {
            this.customerService.authenticate(undefined);
        }

        this.customerService.getByAuthentication().subscribe((customerAccount: CustomerAccount): void => {
            this.customerAccount = customerAccount;
        });

        if (!this.commonService.IsPlatformBrowser()) return;

        setInterval(() => {
            // TODO: remove that stuff and make it clean
            this.customerAccount = this.customerService.customer;
        }, 1000);
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
    }

    fetchBackNavigationRoute(): string {
        /* Checks if info or checkout is open using serviceUID href,
         * then return to the service select order or overview page.
         */
        return this.serviceService.fetchServiceUIDByLocation() ?? '/bestellen';
    }

    openOverlay(): void {
        this.overlayService.open(this.viewContainerRef, LoginComponent, {
            title: 'Anmelden',
            iconType: 'account',
            description: '',
        });
    }
}
