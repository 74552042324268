<ng-container>
    @if (showForm) {
        <span class="customer-info__view">
            <div class="customer-info__header">
                <span class="customer-info__title dynamic__title--md dynamic__text--center">
                    Wir brauchen noch Kontaktdaten um deine Bestellung weiterzuleiten
                </span>
            </div>
            <span>
                @if (formModel.length) {
                    <app-dynamic-form
                        submitButtonText="Bestellung absenden"
                        [listMenuOptions]="formInterestListMenu"
                        [formControls]="formModel"
                        [textAreaControl]="formTextArea"
                        (successSubmit)="submit($event.formGroupData, $event.selectedMenuOption)"></app-dynamic-form>
                }
                <span class="customer-info__subtitle dynamic__text--center dynamic__title--sm"
                    >Durch Anklicken von Bestellung absenden bestätigst du den Warenkorb, deine eingegeben Daten &
                    stimmst unserer
                    <a class="customer-info__link" routerLink="/impressum" target="_blank"> Datenschutzerklärung </a>
                    zu.
                </span>
            </span>
        </span>
    } @else {
        <span fxLayout="column" fxLayoutAlign="center center" style="width: 100vw; margin-top: 4rem">
            <img style="height: 13rem" src="assets/loading.svg" />
        </span>
    }
</ng-container>
