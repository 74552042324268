<div class="element-overlay__view">
    <section class="element-overlay__background dynamic__position--absolute" (click)="close()"></section>
    <section
        *ngIf="visible"
        class="element-overlay__modal dynamic__padding--sm dynamic__position--relative"
        [@openOverlayAnimation]="true">
        <app-element-overlay-base
            [iconType]="iconType"
            [iconSize]="iconSize"
            [iconColor]="iconColor"
            [title]="title"
            [description]="description"
            (close)="close()">
            <ng-container #lazyLoadedComponent></ng-container>
        </app-element-overlay-base>
    </section>
</div>
