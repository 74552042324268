import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-element-overlay-base',
    templateUrl: './element-overlay-base.component.html',
    styleUrls: ['./element-overlay-base.component.scss'],
})
export class ElementOverlayBaseComponent {
    @Input() iconType: string = '';
    @Input() iconSize: string = '25px';
    @Input() iconColor: string = '#000000';
    @Input() title: string = '';
    @Input() description: string = '';

    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() close: EventEmitter<void> = new EventEmitter<void>();
}
