<ng-container (keydown.enter)="onSubmit()">
    <span class="login__view dynamic__padding--md">
        <div *ngIf="!emailConfirmed"
             class="login__title dynamic__stretch--width">
            <span class="dynamic__text dynamic__text--bold login__title__head">Wie lautet deine E-Mail-Adresse?</span>
            <span class="dynamic__text--sm">Wir prüfen, ob du ein Kundenkonto hast</span>
        </div>
        <div *ngIf="emailConfirmed"
             class="login__title dynamic__stretch--width">
            <span class="dynamic__text dynamic__text--bold login__title__head">Willkommen zurück!</span>
            <span class="dynamic__text--sm">Mit Passwort anmelden</span>
        </div>
        <form class="login__form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div class="login__form__field" *ngIf="!emailConfirmed">
                <input class="login__form__field__input dynamic__text--sm" placeholder="E-Mail"
                       formControlName="email"
                       type="email"
                       [class.login__form__field__input--invalid]="
                        this.loginForm.controls['email'].dirty &&
                        this.loginForm.controls['email'].invalid
                    "/>
                <div class="dynamic__text--xs login__form__field__error"
                     *ngIf="loginForm.get('email')?.invalid && (loginForm.get('email')?.dirty || loginForm.get('email')?.touched)">
                        Ungültige E-Mail
                </div>
            </div>
            <div *ngIf="emailConfirmed" class="dynamic__text--sm dynamic__text--bold">
                {{ loginForm.get('email')?.value }}
            </div>

            <div class="login__form__field" *ngIf="emailConfirmed">
                <input class="login__form__field__input dynamic__text--sm" placeholder="Passwort"
                       formControlName="password" type="password"
                       [class.login__form__field__input--invalid]="
                        this.loginForm.controls['password'].dirty &&
                        this.loginForm.controls['password'].invalid
                    "/>
                <div class="dynamic__text--xs login__form__field__error"
                     *ngIf="loginForm.get('password')?.invalid && (loginForm.get('password')?.dirty || loginForm.get('password')?.touched)">
                        <div *ngIf="loginForm.get('password')?.errors?.['required']">
                            Ungültiges Passwort
                        </div>
                        <div *ngIf="loginForm.get('password')?.errors?.['minlength']">
                            Ungültiges Passwort (min. 6 Zeichnen)
                        </div>
                        <div *ngIf="loginForm.get('password')?.errors?.['serverError']">
                            Falsches Passwort
                        </div>
                </div>
            </div>

            <button *ngIf="!emailConfirmed"
                    class="dynamic__button dynamic__stretch--width"
                    [class.dynamic__button--disabled]="loginForm.get('email')?.invalid"
                    [disabled]="loginForm.get('email')?.invalid"
                    (click)="confirmEmail()">Weiter</button>
            <button *ngIf="emailConfirmed"
                    class="dynamic__button dynamic__stretch--width"
                    type="submit"
                    [class.dynamic__button--disabled]="loginForm.invalid"
                    [disabled]="loginForm.invalid">Mit Passwort anmelden</button>
        </form>
    </span>
</ng-container>
