<ng-container>
    <header class="app__header dynamic__stretch--width">
        <app-element-header></app-element-header>
    </header>

    <main class="app__outlet">
        <router-outlet></router-outlet>
    </main>

    <footer>
<!--        <app-element-footer class="app__footer dynamic__stretch&#45;&#45;width"-->
<!--                            *ngIf="!router.isActive('/service/order', false)">-->
<!--        </app-element-footer>-->
    </footer>
</ng-container>
