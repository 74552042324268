import { Component, inject } from '@angular/core';
import { Product } from '../../lib/interfaces/Product';
import { OrderService } from '../../services/order/order.service';
import { OverlayService } from '../../services/overlay/overlay.service';

@Component({
    selector: 'app-order-product-select-options',
    templateUrl: './order-product-select-options.component.html',
    styleUrls: ['./order-product-select-options.component.scss'],
})
export class OrderProductSelectOptionsComponent {
    protected orderService: OrderService = inject(OrderService);
    private overlayService: OverlayService = inject(OverlayService);

    selectOption(product: Product, option: Product): void {
        this.orderService.registerOrderProduct(product, [option]);
        this.destroy();
    }

    destroy(): void {
        this.overlayService.close();
    }
}
