import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventService } from '../../services/event/event.service';
import { Event } from '../../lib/interfaces/Event';
import { OrderService } from '../../services/order/order.service';

@Component({
    selector: 'app-order-event',
    templateUrl: './order-event.component.html',
    styleUrls: ['./order-event.component.scss'],
})
export class OrderEventComponent {
    public event: Event = {} as Event;

    constructor(
        private activatedRoute: ActivatedRoute,
        private eventService: EventService,
        private orderService: OrderService,
    ) {
        const eventRef = this.activatedRoute.snapshot.paramMap.get('eventId');
        if (!eventRef) return;
        this.eventService.fetchEventById(parseInt(eventRef)).subscribe((event: Event) => {
            this.event = event;
            this.orderService.order.event = event;
        });
    }
}
